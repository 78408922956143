@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@600&display=swap");
#NavBackDrop {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: white;
}

.h1WelPar {
    background-color: transparent;
    overflow: hidden;
}

.h1WelPar1 {
    font-family: "Manrope", sans-serif;
    width: 95%;
    overflow: hidden;
    text-align: right;
    font-size: 0.5rem;
}

.h1Wel1 {
    transform: translateY(100%);
    font-size: 5rem;
    letter-spacing: 0.18rem;
    font-weight: 900;
    font-family: "Manrope", sans-serif;
}

.h1Wel2 {
    transform: translateY(100%);
}

#slider {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: flex-end;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: rgb(153, 255, 153);
    transform: translateY(100%);
}

@media only screen and (max-width: 500px) {
    .h1Wel1 {
        font-size: 4rem;
    }
    .h1Wel2 {
        font-size: 0.8rem;
    }
}

@media only screen and (max-width: 350px) {
    .h1Wel1 {
        font-size: 3rem;
    }
}