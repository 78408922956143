.mDiv {
    margin-top: 100px;
    text-align: center;
}

.mDiv h1 {
    font-family: "Nunito", sans-serif;
    margin-bottom: 20px;
}

.conDiv {
    display: flex;
    justify-content: space-evenly;
}

.fullS {
    border-style: solid;
    border-width: 1px;
    border-color: rgb(179, 179, 179);
    border-radius: 5px;
    padding: 1%;
    transition: all 0.5s;
}

.fullS:hover {
    border-color: rgb(0, 180, 0);
}

.fullS svg {
    color: rgb(0, 180, 0);
}

.detSer {
    font-family: "Nunito", sans-serif;
}

.title {
    font-size: 1.5rem;
}


/* @media only screen and (max-width: 740px) {
    .mDiv {
        width: 740px;
    }
} */