#darkSvgIcon {
    color: white;
}

#darkBurger div {
    background-color: white;
}

#darkNavMobo {
    background-color: #0a0a0a;
    color: white;
}