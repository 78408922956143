.mDiv {
    margin-top: 50px;
    background-color: rgb(214, 255, 214);
    text-align: center;
    padding: 1%;
    color: black;
}

.darkmDiv {
    margin-top: 50px;
    background-color: rgb(0, 180, 0);
    text-align: center;
    padding: 1%;
    color: white;
}