.mDiv {
  margin-top: 50px;
  text-align: center;
}

.mDiv h1 {
  font-family: "Nunito", sans-serif;
}

.pDiv {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-evenly;
  padding: 20px;
}

.PinpDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#searchInp {
  outline: none;
  background-image: url("./../../img/portfolio.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  padding: 1% 3%;
  width: 60%;
  border-style: solid;
  border-width: 1px;
  border-color: grey;
  border-radius: 5px;
  transition: all 0.3s;
}

#searchInp::placeholder {
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.12rem;
  text-transform: capitalize;
}

#searchInp:hover {
  transition: all 0.3s;
  border-color: transparent transparent rgb(0 180 0) transparent;
}

#searchInp:focus {
  border-color: transparent transparent rgb(0 180 0) transparent;
  transition: all 0.3s;
}

@media only screen and (max-width: 800px) {
  #searchInp {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 740px) {
  .mDiv {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  #searchInp {
    width: 95%;
    padding: 1% 1%;
  }
}

@media only screen and (max-width: 600px) {
  #searchInp {
    padding: 3% 1%;
  }
}
