@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@700&family=Oswald:wght@500&display=swap");
.mDiv {
    position: relative;
    width: 100%;
    display: flex;
    padding-top: 100px;
    justify-content: space-evenly;
}

.imgme {
    z-index: 2;
    height: 796.135px;
}

.mybioText {
    width: 412px;
    height: 796.135px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;
}

.mDiv h1 {
    text-align: center;
    font-family: "Nunito", sans-serif;
}

.mDiv p {
    text-align: justify;
}

.barDiv {
    width: 100%;
}

.barDiv p {
    font-family: "Nunito", sans-serif;
}

.fullL {
    border-radius: 5px;
    background-color: grey;
    height: 4px;
    width: 100%;
    transition: all 0.3s;
}

.greenBar {
    border-radius: 5px;
    background-color: green;
    height: 4px;
}

#gb1 {
    width: 92%;
}

#gb2 {
    width: 90%;
}

#gb3 {
    width: 93%;
}

#gb4 {
    width: 91%;
}

.gbDivV {
    background-color: lightgreen;
    float: right;
    margin: 1% 5% 0px 0px;
    padding: 1% 3%;
    border-radius: 10px;
    font-size: 0.8rem;
    opacity: 0;
}

.fullL:hover>.gbDivV {
    opacity: 1;
    transition: all 0.3s;
}

.mBp {
    margin: 40px 0px 50px 0px;
}

.myBioH1:hover {
    text-decoration: underline;
}

.CvLink {
    width: 96.25px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    text-decoration: none;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(0, 180, 0);
    color: rgb(0, 180, 0);
    border-radius: 5px;
    padding: 2% 8%;
    transition: all 0.7s;
}

.CvLink:hover {
    box-shadow: inset -10.5em 0 0 0 rgb(0, 180, 0), inset 10.5em 0 0 0 rgb(0, 180, 0);
    color: white;
}

.CvLink:hover>.downloadtext {
    display: none;
}

.CvLink:hover>.downloadIcon {
    display: flex;
}

.downloadIcon {
    display: none;
}

.dotsDiv {
    width: 100%;
    position: absolute;
    top: 5%;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    z-index: -1;
}

.myBioBtnDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

@media only screen and (max-width: 1100px) {
    .mDiv {
        flex-direction: column;
    }
    .ResimgDiv {
        display: flex;
        justify-content: center;
    }
    .mybioText {
        display: flex;
        justify-content: center;
        width: 60%;
        margin: 0px 20%;
    }
}

@media only screen and (max-width: 800px) {
    .imgme {
        width: 100%;
        height: auto;
    }
    .ResimgDiv {
        justify-content: space-evenly;
    }
    .mybioText {
        width: 60%;
        margin: 0px 20%;
    }
    .CvLink {
        padding: 3% 8%;
    }
    .myBioBtnDiv {
        flex-direction: column;
    }
}

@media only screen and (max-width: 400px) {
    .mybioText {
        margin-top: 10px;
    }
}

@media only screen and (max-width: 330px) {
    .mybioText {
        margin-top: 30px;
    }
}